import { Link } from "@business-finland/wif-ui-lib";
import { useRouter } from "next/router";

import { MGNL } from "../../constants/magnolia";

const style = { color: "inherit" };

export default function Anchor({
  href,
  id,
  children,
}: React.ComponentProps<"a">): JSX.Element | null {
  const { isPreview } = useRouter();

  if (!href) return <>{children}</>;

  return (
    <Link id={id} style={style} {...formatLink(href, isPreview)}>
      {children}
    </Link>
  );
}

/**
 * Richtext href internal and external link handler
 * @date 10/6/2022 - 9:35:36 AM
 *
 * @export
 * @param {string} link
 */
function formatLink(
  link: string,
  isPreview = false
): { href: string; target?: "_blank" } {
  const decodedLink = decodeEncodedLink(link);

  //   Handle empty or root node links
  if (decodedLink === MGNL.AUTHOR_PATH + MGNL.ROOT_NODE || decodedLink === "") {
    return { href: "/" };
  }

  // Handle external links
  if (!decodedLink.startsWith("/"))
    return { href: decodedLink, target: "_blank" };

  const modifiedLink = decodedLink
    .replace(MGNL.AUTHOR_PATH, "")
    .replace(MGNL.ROOT_NODE, "")
    .replace("/fi", "")
    .replace("/sv", "")
    .replace(".html", "");

  // Prepend MGNL host to DAM links
  if (modifiedLink.startsWith("/dam")) {
    return {
      href: new URL(modifiedLink, MGNL.HOST(isPreview)).toString(),
      target: "_blank",
    };
  }

  // Internal links
  return { href: modifiedLink };
}

/** Parse link if wrapped in an $ object. */
function decodeEncodedLink(link: string): string {
  const decodedLink = decodeURI(link);

  if (decodedLink.startsWith("$")) {
    try {
      return decodedLink.split("path:{")[1].split("}")[0];
    } catch {
      return "";
    }
  }

  return link;
}
