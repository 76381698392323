import Markdown from "markdown-to-jsx";

import Anchor from "./Anchor";

const options: React.ComponentProps<typeof Markdown>["options"] = {
  overrides: { a: Anchor },
};

/**
 * Component to handle html tags inside richText string prop
 * and returns html tags as react instead of regular html.
 * This allows us to use UI components, styling etc.
 *
 * Can be extended to have more tags, currently have <p> and <a> tags
 *
 * @date 10/6/2022 - 9:53:50 AM
 */
export function HtmlParser({
  html,
}: {
  html: string | undefined;
}): JSX.Element | null {
  if (!html) return null;

  return <Markdown options={options}>{html}</Markdown>;
}
